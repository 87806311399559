
/* @media screen and (max-width: 768px) {
    #features {
      width: 100%;
    }
    #about,
    #services,
    #testimonials,
    #team,
    #contact,
    #footer {
      width: 100%;
    }
  
    #portfolio {
      width: 100%;
    }
  }
   */

   .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color:rgb(82, 75, 75)
   }
   .ant-tabs .ant-tabs-tab-btn:hover {
    color:rgb(196, 180, 180)
   }

   #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #ff7a63;
  }

  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ff7a63;
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }

  @-webkit-keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }



.site-layout  nav {
    background-color: #fff; /* sets the background color to a dark gray */
  }
  
  .site-layout ul {
    list-style-type: none; /* removes bullet points from the list */
    margin: 0; /* removes the default margin */
    padding: 0; /* removes the default padding */
    overflow: hidden; /* hides any elements that extend beyond the container */
  }
  
  .site-layout li {
    float: right; /* floats the list items to the right of the container */
  }
  
  .site-layout li a {
    display: block; /* displays the link as a block element */
    color: rgb(117, 101, 101); /* sets the text color to white */
    text-align: center; /* centers the text */
    padding: 14px 16px; /* adds padding to the link */
    text-decoration: none; /* removes the underline from the link */
    font-weight: bold; 
  }
  
  .site-layout  li a:hover {
    color: rgb(43, 42, 42); /* changes the background color when hovering over the link */
  }
  
.img-auth {
  width: 100px;
 margin: 10px;

}
.nav-auth {
  border-bottom: 1px solid #ccc;
}
.brand-auth {
  color:#fff;
  font-weight: bold;
  font-size:1.6rem;
  letter-spacing: 2px;
}

/* Basic styles for Ant Design input field */
.ant-myinput {
  display: inline-block;
  width: 50%;
  height: 32px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  margin-bottom:20px
}

.ant-myinput:focus,
.ant-myinput-focused {
  outline: none;
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-myinput-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
