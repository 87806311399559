#event-media {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#img {
  width: fit-content;
  height: 200px;
  /* padding: 1%; */
  /* margin: auto; */
}
