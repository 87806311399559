.stat_row {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* width:100% !important */
}
.stat_item {
    flex-basis: 180px;
    flex-grow: 1;
    justify-items: center;
    margin:1rem
}