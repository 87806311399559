.founder-img img {
    /* object-fit: contain; */
    width:300px;
    height:370px;
    border-radius: 50%;
    display: inline-block;
    background-size: cover;
    background-position: center;
}

@media screen and (min-width:768px) {
    .founder-img {
        margin-right:20px
    }
}

@media screen and (max-width:768px){
    .founder {
        flex-direction: column;
    }
}

.founder-img {
    
}
.founder {
    display: flex;
    align-items: center;
    justify-content: center;
}