
  .picture-grid {
    display: flex;
    flex-wrap: wrap;
}

.picture-grid-item {
    width: 33.33%;
    object-fit: cover;
    flex-grow: 1;
    height: auto;
    padding:0.2rem
}

@media screen and (max-width: 768px) {
  .picture-grid-item {
    width: 100%;
    flex-direction: column
  }
}