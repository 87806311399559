
.row-c {
    margin-right: -15px;
    margin-left: -15px;
    overflow-x: hidden;
}

.row-c:before, .row:after {
    display: table;
    content: " ";
}

ul.default li, ol.default li {
    list-style: none;
}

ul.default, ol.default {
    margin: 0;
    padding: 0;
}
.person-card {
    position: relative;
    flex-basis: 350px;
    flex-shrink: 0;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
   

}
.default {
    display:flex;
    flex-basis: 350px;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: space-between;  
}
@media screen and (max-width: 768px){
    .default {
        flex-direction: column;
        flex-basis: 350px;
        justify-content: center;
        align-items: center;
    }
    .person-card {
        flex-grow: 1;
        justify-content: space-evenly;
    }
}
.default.ul .default.li, .default.ol .default.li {
    margin-bottom: 10px;
    line-height: 1.8;
}
 .person-image {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 60%;
    max-height: 60%;
    /* padding-bottom: 132%; */
}
.bg-purple {
    color:#fff;
    background-color: transparent
}

.bg-purple a {
    color: #009976;
    cursor: pointer;
    zoom: 1;
    background-color: transparent;
}
.person-card .person-image .child-search-icon a, .person-card .person-image .child-search-img {
    object-fit: cover;
    object-position: 50% 50%;
    /* font-family: "object-fit: cover; object-position: 50% 50%"; */
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
/* card */
.card-c {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card-c:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .container-c {
    padding: 2px 16px;
    text-align: center;
  }
  .img-c{
    overflow: hidden;
    position: relative;
  }
 
  .img-c:hover {
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 0.5s ease-in-out;
    scale: 1.05;

}
.text-block {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0,153,118,.61);
    color: white;
    cursor: pointer;
    padding:1.2rem
  }
  .text-block-r {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #ff6863;
    color: white;
    cursor: pointer;
    padding:1rem
  }
 .text-item {
    font-weight: bold;
    font-size:18px;
    /* padding-right: 15px; */
    margin-left: -10px;
    font-family: "AvenirLT-Heavy";
    text-transform: uppercase;
 }
 .text-item-right {
  font-weight: bold;
  font-size:18px;
  padding-right: 10px;
  margin-right: -10px;
  font-family: "AvenirLT-Heavy";
  text-transform: uppercase;
}
 .text-name {
    color: #009976;
    font-family: "AvenirLT-Medium";
    font-size: 24px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: bold;
 }

 .text-details {
font-family:cursive;
    font-size: 12px;
   font-style: italic;
 }

 .text-btn {
    /* width: 100%; */
    outline: 0;
    border: 0;
    color: #fff;
    background-color: #009976;
    padding: 0.5rem 1rem;
    
 }

 .details-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,153,118,.61);
    color: white;
    width: 100%; 
    padding: 1rem;
    text-align: left;
 }
.button-c {
    background-color: rgba(0,153,118,.61);
    border:1px solid #fff;
   text-align: center;
   padding: 1rem 1rem;

}
 

 /* test */
 :root {
    --red: #ef233c;
    --darkred: #ff6863;
    --platinum: #e5e5e5;
    --black: #2b2d42;
    --white: #fff;
    --thumb: #edf2f4;
  }
  
  .m_container {
    max-width: 1400px;
    padding: 0 15px;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 32px;
    margin-bottom: 1em;
  }
  
  .cards {
    display: flex;
    padding: 25px 0px;
    list-style: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    padding: 10px;
    background: transparent;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
    scroll-snap-align: start;
    transition: all 0.2s;
  }
  
  .card:not(:last-child) {
    margin-right: 10px;
  }
  
  .card .card-title {
    font-size: 20px;
  }
  
  .card .card-content {
    /* margin: 20px 0; */
    max-width: 85%;
  }
  
  .card .card-link-wrapper {
    margin-top: auto;
  }
  
  .card .card-link {
    display: inline-block;
    text-decoration: none;
    color: white;
    background: var(--red);
    padding: 6px 12px;
    border-radius: 8px;
    transition: background 0.2s;
  }
  
  .card:hover .card-link {
    background: var(--darkred);
    
  }
  
  .cards::-webkit-scrollbar {
    height: 12px;
  }
  
  .cards::-webkit-scrollbar-thumb,
  .cards::-webkit-scrollbar-track {
    border-radius: 92px;
  }
  
  .cards::-webkit-scrollbar-thumb {
    background: var(--darkred);
  }
  
  .cards::-webkit-scrollbar-track {
    background: var(--thumb);
  }
  
  @media (min-width: 500px) {
    .card {
      flex-basis: calc(50% - 10px);
    }
  
    .card:not(:last-child) {
      margin-right: 20px;
    }
  }
  
  @media (min-width: 700px) {
    .card {
      flex-basis: calc(calc(100% / 3) - 20px);
    }
  
    .card:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  @media (min-width: 1100px) {
    .card {
      flex-basis: calc(25% - 30px);
    }
  
    .card:not(:last-child) {
      margin-right: 40px;
    }
  }
  
  .card .card_img {
    object-fit: cover;
    object-position: 50% 50%;
    /* font-family: "object-fit: cover; object-position: 50% 50%"; */
    width: 100%;
    
  }

  .my-input {
    border: 1px solid #fff;
    padding: 0.2rem 1rem;
    margin-bottom: 10px;
    margin-right: 10px;
    color: #000;
    display: inline;
    width: 60%;
  }

.my-input:focus {
    border: 1px solid rgba(247, 242, 253, 1);
    outline: none;
    box-shadow: 0 0 20px rgb(233, 233, 233)

}
.element{

    text-overflow: ellipsis;
  
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }

  .pay-btn {
    display: inline;
    outline:none;
    border: 0;
    padding: 0.5rem 1rem;
    background-color:dodgerblue;
    border-radius: 5px;
  }
  .pay-btn:hover {
    background-color:rgb(3, 126, 248);
  }