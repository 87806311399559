.landing {
  margin: 0;
  padding: 0;
}
.form {
  margin: auto;
  width: 35%;
  height: 70vh;
  box-sizing: border-box;
  padding: 40px;
  /* margin-top: 72px; */
  margin-bottom: 200px;
  background: #ecf0f3;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px #fff;
}
.form button {
  width: 100%;
}
.form p {
  color: #9e9a9a;
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImageR {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage img {
  width: 50%;
  height: 15%;
  margin: 10px;
}
.logoImageR img {
  width: 30%;
  height: 12%;
}
.logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  color: #37517e;
  background: -webkit-linear-gradient(#37517e, #9cbbf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.my-form h1 {
  color: #b9b8b8;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.my-form {
  margin-bottom: 6%;
  width: 100%;
}
.forgot-password {
  margin-left: 150px;
  width: 100%;
}
.my-container {
  width: 60%;
  height: 70vh;
  margin: auto;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 72px;
  margin-bottom: 200px;
  background: #ecf0f3;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px #fff;
}
.correct-text {
  margin-left: 4px;
}
/* .wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: minmax(400px, auto);
  gap: 10px;
} */
[data-theme="compact"] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}
/* .dashboards {
  display: inline-flex;
  flex-direction: column;
  
  flex-wrap: wrap;
  align-items: center;
} */

/* .box {
  width: 40vw;
  margin: 2rem;
  height: 50vh;
} */

/* Test */
.dashboards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.box {
  width: 40vw;
  margin: 20px;
  height: 50vh;
  flex-grow: 1;
}
.dashboards h2,
.total h2 {
  font-size: 1rem;
  text-align: center;
  margin: 10px;
}

.dashboard-head {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.total {
  display: flex;
  flex-direction: row;
  /* margin: 20px 0 20px; */
  flex-wrap: wrap;
}

.item {
  width: 18rem;
  margin: 2rem;
  height: 18rem;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px #fff;
  border-radius: 20px;
  padding: 15px;
  flex-grow: 1;
}

item:hover {
  transform: scale(1.5);
}

.zoom {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.2);

  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/* .items {
  width: 12rem;
  height: 10rem;
  margin: 1rem;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px #fff;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
} */
/* .items:nth-child(1) {
  background-color: #8884d8;
} */

.items h2 {
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem;
}
.container-sensitivity {
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 30vw;
}

.container-sensitivity h1 {
  font-size: 1.2rem;
  color: #333;
  padding: 10px;
  white-space: nowrap;
}

.fields {
  margin: 5px;
}
