.circle {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background: linear-gradient(to right, #bd605a 0%, #5ca9fb 100%);
  margin: 2rem;
  text-align: center;
  align-content: space-around;
  color: white;
  font-size: 2rem;
}

.circle span {
  position: relative;
  top: 70px;
}
.circles {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.statistics-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.stat-box {
  width: 150px;
  height: 150px;
  background-color: #f0f8ff; /* Light blue background */
  border: 2px solid #007bff; /* Border color */
  border-radius: 10px; /* Slightly rounded edges for squares */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.stat-box:hover {
  transform: scale(1.05);
}

.stat-box span {
  font-size: 16px;
  color: #333;
  text-align: center;
}

.stat-box h2 {
  font-size: 36px;
  margin: 10px 0 0;
  color: #007bff;
  font-weight: bold;
}
