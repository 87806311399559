/* .catalog-container {
  max-width: 1300px; */
/* margin: 100px auto;
  padding: 10px; */
/* padding: 2.4rem;
  margin: 2rem;
  border: 1px solid #d1d7dc;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; */
/* grid-template-columns: repeat(4, 25%); */
/* gap: 10px; */
/* } */

.catalog-container {
  max-width: 1500px;
  margin: 20px auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}

.donee-card {
  width: 250px;
  margin-top: 16px;
  height: auto;
}
