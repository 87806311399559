@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

.content-more h3,
.content-more h4 {
  font-weight: bold;
}

.content-more p {
  margin: 5px 0;
}

.content-more .badge {
  font-size: 0.9em;
  padding: 5px 10px;
}

.text-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.text-btn:hover {
  background-color: #0056b3;
}

.container-more {
  display: flex;
  flex: 1;

  justify-content: center;
  padding: 1.5rem;
  gap: 1.5rem;
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  background: #f9f9f9;
  border-radius: 10px;
}
.b-img img {
  margin: 2rem;
  width: 300px;
  height: auto;
  object-fit: contain;
}

.nav-logo {
  height: 70px;
  margin: 0;
}
.more-item {
  flex-grow: 1;
}
.content-more p {
  font-family: "Open Sans", sans-serif;
  font-size: 1.7rem;
  line-height: 2.5rem;
}
@media screen and (max-width: 768px) {
  .container-more {
    flex-direction: column;
  }
}
.more-btn {
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  border-radius: 20px;
}
