/* Fix for link on navigation */
.nav li {
    cursor: pointer;
}

.navbar-brand img {
    padding:10px;
    width:165px;
    object-fit: cover;
    margin-top: -35px;
    margin-left: -10px
}

@media screen and (min-width:1100px) {
    .navbar-brand img {
        margin-left: -80px;
        height: 90px
    }
    
}
@media  screen and (min-width: 768px){
    .navbar-brand img {
        margin-left: -20px;
    } 
}

/* donate button */
.badge-btn {
    background-color: rgb(223, 87, 69) !important;
    text-transform: uppercase;
    color:#fff !important;
    border-radius:30px;
    padding:1.5rem 0.5rem;
   font-weight: bold;
}
.badge-btn:hover {
    background-color: rgb(224, 60, 60) !important;
}

/* Dropdown Button */
.dropbtn {
    /* background-color: #04AA6D;
    color: white; */
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {}