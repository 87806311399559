.landing {
  margin: 0;
  padding: 0;
}
.form-d {
  margin: auto;
  width: 40%;
  height: auto;
  box-sizing: border-box;
  padding: 30px;
  /* padding-top: 1rem; */
  /* margin-top: 72px; */
  border-radius: 30px;
  margin-bottom: 200px;
  background: #ecf0f3;
  box-shadow: 14px 14px 20px #d0d2d3, -14px -14px 20px #e1e6e75d;
}

.register {
  width: 70%;
  height: auto;
  border-radius: 10px;
}

.form-d button {
  width: 100%;
}
.form-d p {
  color: #9e9a9a;
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImageR {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage img {
  width: 65%;
  height: 20%;
  margin: 5px;
}
.logoImageR img {
  width: 30%;
  height: 12%;
}
.logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform-d: uppercase;
  display: inline-block;
  font-weight: 700;
  color: #37517e;
  background: -webkit-linear-gradient(#37517e, #9cbbf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.my-form-d h1 {
  color: #b9b8b8;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.my-form-d {
  margin-bottom: 6%;
  width: 100%;
}
.forgot-password {
  margin-left: 150px;
  width: 100%;
}

@media screen and (max-width: 745px) {
  .form-d {
    width: 70%;
  }
}

@media screen and (max-width: 870px) {
  .form-d {
    width: 60%;
    /* height: 50vh; */
  }
}

@media screen and (max-width: 440px) {
  .form-d {
    width: 95%;
  }
}
