.project_item .project_img {
    width: auto;
    height: 350px;
    object-fit: contain;
    object-position: center center;
    margin-bottom: 50px;
}

.project_item div {
    text-align: center
}
.project_item h4{
    color: #fff;
    font-size:1.5rem
}

 .project_row {
    display: flex;
 }
 
 .project_item{
    width: 50%;
 }

 @media screen and (max-width: 768px){
    .project_item{
        width: 100%;
     }
    .project_row {
        flex-direction: column;
    }
 }
 