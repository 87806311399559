.row-about {
    display: flex;
}

.row-item {
    width:50%;
    margin: 20px;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .row-about {
        flex-direction: column;
        align-items: center;
    }
    .row-item {
        width:100%;
        justify-content: center;
    }
}