.beneficiary-thumbnail {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}
.share-section {
  display: flex;
  align-items: center;
}
